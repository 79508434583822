<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a
          @click="$router.back()"
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom gutter-b" v-if="currentUjian">
          <div class="card-body">
            <div class="d-flex mb-9">
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex flex-column mr-3">
                    <a
                      href="#"
                      class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                    >
                      {{ currentUjian.name }}
                    </a>
                    <a
                      href="#"
                      class="text-dark-50 text-hover-primary font-size-h7 font-weight-bold mr-3"
                    >
                      <!-- {{ currentUjian.description }} -->
                    </a>
                  </div>

                  <div class="my-lg-0 my-3">
                    <button
                      v-b-toggle.deskripsi
                      class="btn btn-light-info font-weight-bolder font-size-sm mr-2"
                    >
                      Lihat Deskripsi
                    </button>
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <div class="d-flex flex-wrap mb-4">
                      <a
                        href="#"
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                      >
                        <i class="flaticon-clock-1 mr-2 font-size-lg"></i>
                        {{ currentUjian.time }} Menit
                      </a>
                      <a
                        href="#"
                        class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        ><i class="flaticon2-calendar-2 mr-2 font-size-lg"></i>
                        {{
                          currentUjian.opening_time | moment("dddd, LL HH:mm")
                        }}
                        -
                        {{
                          currentUjian.closing_time | moment("dddd, LL HH:mm")
                        }}
                      </a>
                    </div>

                    <b-collapse id="deskripsi" class="mt-2">
                      <b-card>
                        <div v-html="currentUjian.description"></div>
                      </b-card>
                    </b-collapse>
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->

            <div class="separator separator-solid"></div>

            <!--begin::Items-->
            <div class="d-flex align-items-center flex-wrap mt-8">
              <!--begin::Item-->
              <div class="d-flex align-items-center mr-5 mb-2">
                <span class="mr-4">
                  <i
                    class="flaticon-file-2 display-4 text-muted font-weight-bold"
                  ></i>
                </span>
                <div class="d-flex flex-column text-dark-75">
                  <span class="font-weight-bolder font-size-sm"
                    >Jumlah Soal</span
                  >
                  <span class="font-weight-bolder font-size-h5">
                    <span class="text-dark-50 font-weight-bold">
                      {{ currentUjian.question_pack_id.questions.length }}
                    </span>
                  </span>
                </div>
              </div>
              <!--end::Item-->
            </div>
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Evaluasi Soal
              </span>
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body pt-4">
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              @filtered="onFiltered"
              :items="questions"
              :fields="fields"
              :busy="isLoading"
              show-empty
              class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada evaluasi soal ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada evaluasi soal ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>

              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Soal:
                </div>
                <div v-html="row.item.question"></div>
                <div
                  class="text-dark-75 font-weight-bolder mb-1 font-size-sm"
                  v-if="!row.item.showOnlyQuestion"
                >
                  Jawaban:
                  <div class="jawaban">
                    <div
                      :class="`card card-custom card-stretch border shadow-none`"
                      v-for="(jawaban, i) in row.item.answer"
                      :key="`${i}`"
                    >
                      <div
                        class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
                      >
                        <div v-html="jawaban.answer"></div>
                        <div>{{ jawaban.point }} pts</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(soal)="data">
                <div
                  v-html="data.item.question"
                  style="
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  "
                ></div>
              </template>

              <template #cell(kode_soal)="data">
                {{ data.item.quetion_code }}
              </template>

              <template #cell(tipe)="data">
                <span
                  class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                >
                  {{ data.item.type }}
                </span>
              </template>

              <template #cell(bobot)="data">
                <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
                <!--            {{ topik }}-->
                <!--          </b-badge>-->
                <span
                  class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                >
                  {{ data.item.bobot }} pts
                </span>
              </template>

              <template #cell(topic)="data">
                <span
                  class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                  v-for="topik in data.item.topic"
                  :key="topik"
                >
                  {{ topik }}
                </span>
              </template>

              <template #cell(action)="data">
                <div class="d-flex justify-content-end">
                  <span
                    @click="toggleQuestion(data)"
                    class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                  >
                    <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                    <i
                      :class="`ki ki-bold-arrow-${
                        data.detailsShowing ? 'up' : 'down mt-1'
                      } icon-sm`"
                    ></i>
                  </span>
                  <span
                    @click="toggleAnswer(data)"
                    class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
                    >Daftar Jawaban</span
                  >
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_QUESTION_RECAP } from "@/core/services/store/ujian.module";
import { GET_MAPEL } from "@/core/services/store/mapel.module";
import { GET_ONE_ATTEMPT } from "@/core/services/store/attempt.module";
import { GET_HISTORI_NILAI } from "@/core/services/store/rekapnilai.module";
export default {
  name: "HistoryUjian",
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "type",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topic",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "scores",
          label: "Nilai",
          sortable: true,
        },
        {
          class: "text-center",
          key: "action",
          label: "Aksi",
          sortable: false,
          thStyle: { width: "10px" },
        },
      ],
      currentUjian: null,
      selectedMapel: null,
      questions: [],
      items: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Evaluasi Soal" }]);
    this.getData();
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    options() {
      var opt = [];
      return opt;
    },
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.currentUser.role == "student";
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_QUESTION_RECAP, this.id)
        .then((response) => {
          this.isLoading = false;
          this.currentUjian = response.data.exam;
          this.questions = response.data.data;
          this.questions.forEach((k) => {
            this.$nextTick(() => {
              this.$set(k, "_showDetails", true);
              this.$set(k, "showOnlyQuestion", true);
            });
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Recap Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    toggleQuestion(data) {
      this.$nextTick(() => {
        if (!data.item.showOnlyQuestion) {
          this.$set(data.item, "_showDetails", true);
        } else {
          this.$set(data.item, "_showDetails", !data.item._showDetails);
        }
        this.$set(data.item, "showOnlyQuestion", true);
      });
    },
    toggleAnswer(data) {
      this.$nextTick(() => {
        if (data.item.showOnlyQuestion) {
          this.$set(data.item, "showOnlyQuestion", false);
        } else {
          this.$set(data.item, "showOnlyQuestion", true);
        }
      });
    },
  },
};
</script>

<style scoped>
/deep/ .vs__dropdown-toggle {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 5px;
  border: 1px solid #e4e6ef;
}
</style>
